<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && isCanVisibleMenuLink(item)"
    class="nav-item"
    @click="$emit('click')"
    :class="{
      'active': isActive
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      :disabled="isDisabled(item)"
    >
     
      <div :class="isActive ? `menu-icon icon-${item.icon}-active` : `menu-icon icon-${item.icon}`" style="width: 28px; height: 28px; background-size: 28px;" />
      <span class="menu-title text-truncate" style='margin-left: 10px; line-height: 20px; margin-top: 3px; max-width: 180px; flex: 1;'>{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
       <feather-icon 
          v-if="item.route == 'user-referral' && !User.self.is_referral"
          v-b-popover.hover.top="$t('popovers.open-referral')"
          icon="AlertCircleIcon" 
          class="cursor-pointer info-hover text-muted" 
          style="margin: 0px 0px 0px 5px; height: 23px; width: 23px;"
      />
      <feather-icon 
          v-if="item.route == 'user-account' && !User.self.is_verify"
          v-b-popover.hover.top="$t('popovers.confirm-email')"
          icon="AlertCircleIcon" 
          class="cursor-pointer info-hover text-danger" 
          style="margin: 0px 0px 0px 5px; height: 23px; width: 23px;"
      />
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import User from "@/modules/user"

export default {
  data() {
    return {
      User,
    }
  },
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  methods: {
    isCanVisibleMenuLink( item ) {

      if (item.route == 'user-orders' && User.self.diagnostic_orders == 0) return false;
      
      return true;
          
    },
    isDisabled( item ) {

      if (item.route == 'user-referral' && !User.self.is_referral) return true;

      return false;

    }
  }

}
</script>
