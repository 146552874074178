<template>
    <div>
        <div id="bottom-nav" class="bottom-nav-bar d-flex justify-content-between">
            <bottom-nav-menu-item v-for="item in bottomNav" :key="item.header || item.title" :item="item" />
            <b-link v-b-toggle.sidebar-right class="d-flex align-items-center">
                <div :class="isSidebarActive ? 'menu-icon icon-more-active' : 'menu-icon icon-more'"></div>
                <span :class="isSidebarActive ? `menu-link-active` : `menu-link-inactive`">Ещё</span>
            </b-link>
        </div>

        <b-sidebar id="sidebar-right" v-model='isSidebarActive' no-header no-header-close right backdrop shadow>
            <div style="padding: 17px 16px; height: 100%; padding-bottom: 56px;">
                <!-- <div class="text-black" style="font-size: 20px; font-weight: 600; padding: 0 16px; margin-bottom: 0px;">
                    Ещё
                </div> -->
                <div class="sidebar-menu-list d-flex flex-column justify-content-start"
                    style="height: calc(100% - 150px); overflow-x: hidden; overflow-y: auto;">
                    <ul class="navigation navigation-main" style='overflow-x: hidden; overflow-y: auto;'>
                        <template v-for="item in rightSideNav">
                            <template v-if="item.children">
                                <vertical-nav-menu-link :key="item.header || item.title" :item="item"
                                    @click="toggleCollapse(item)" />
                                <b-collapse v-model="item.visible">
                                    <vertical-nav-menu-link v-for="child in item.children"
                                        :key="child.header || child.title" :item="child" />
                                </b-collapse>
                            </template>
                            <vertical-nav-menu-link v-else :key="item.header || item.title" :item="item" />
                        </template>
                    </ul>

                    <div>
<!-- 
                        <div class='separator'></div>
                        <div class=" d-flex justify-content-between" style="padding: 12px 0px 12px 16px;">
                            <div class="text-black m-0">
                                {{ $t('bottom-nav-menu.dark-theme') }}
                            </div>
                            <div>
                                <b-form-checkbox class="site-mode custom-control-primary p-0 m-0" style="right: 44px;"
                                    name="dark-mode" v-model='skin' value='dark' unchecked-value='light' switch>
                                </b-form-checkbox>
                            </div>
                        </div> -->
                        <template v-if="User.self.group > 1">
                            <div class='separator'></div>
                            <div v-ripple class=" d-flex justify-content-between" style="padding: 12px 0px 12px 16px;">
                                <b-link v-if="$route.meta.group == 'administrator'" :to="{ name: 'user-dashboard' }"
                                    target='_blank' class="text-dark"
                                    style="font-size: 13px; margin: 0; padding: 0; font-weight: 400;">
                                    Кабинет пользователя
                                </b-link>
                                <b-link v-else :to="{ name: 'admin' }" target='_blank' class="text-dark"
                                    style="font-size: 13px; margin: 0; padding: 0; font-weight: 400;">
                                    {{ $t('bottom-nav-menu.admin') }}
                                </b-link>
                            </div>
                        </template>
                        <div class='separator'></div>
                        <div v-ripple class=" d-flex justify-content-between" style="padding: 12px 0px 12px 16px;">
                            <div class="text-dark" @click="logout">
                                {{ $t('bottom-nav-menu.log-out') }}
                            </div>
                        </div>
<!-- 
                        <b-nav-item-dropdown class="select-language const-btn d-md-flex" right>
                            <template #button-content class="const-btn">
                                <img alt="" class="select-language-flag my-auto" :src="locale.flag">
                                <span class=" call-selector">{{ locale.abb }}</span>
                            </template>
                            <b-dropdown-item v-for="(item, index) in locales" :key="index"
                                @click="setLocale(item.code)">
                                <img alt="" class="select-language-flag my-auto" :src="item.flag">
                                {{ item.abb }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown> -->
                    </div>

                </div>
            </div>
        </b-sidebar>

    </div>
</template>

<script>

const TWEEN = require('@tweenjs/tween.js')

import store from '@/store'

import navMenuItems from '@/navigation/bottom'
import BottomNavMenuItem from "./bottom-nav-menu-item/bottom-nav-menu-item"
import VerticalNavMenuLink from "./vertical-nav-menu-link/VerticalNavMenuLink"
import useAppConfig from '@core/app-config/useAppConfig'

import User from "@/modules/user/"

import { getSupportedLocales } from "@/libs/i18n/modules/supported-locales"

export default {

    data() {
        return {
            User,
            navMenuItems,
            isSidebarActive: false,
            skin: useAppConfig().skin.value,
            locales: getSupportedLocales(),
            locale: {
                flag: null,
                abb: null
            },
        }
    },
    methods: {
        toggleCollapse(target) {

            this.rightSideNav.forEach(
                item => { if (target.title !== item.title) item.visible = false; }
            );

            target.visible = !target.visible;

        },

        logout() {
            this.$request.post('sessions.finish').then(() => {
                localStorage.removeItem("access_token");
                localStorage.removeItem("current:user");
                sessionStorage.removeItem('access_token');

                // Remove dark mode
                if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')

                this.$router.replace("/authorization");
            });
        },

        setLocale(locale) {
            this.$i18n.locale = locale
            this.locale = this.locales.find(loc => loc.code === locale);
            User.changeLocale(locale);
        }
    },
    components: {
        BottomNavMenuItem,
        VerticalNavMenuLink
    },

    computed: {
        bottomNav() {

            let items = [];

            navMenuItems.forEach(item => {
                if (item.side === 'bottom') {
                    items.push(item);
                }
            });

            return items;
        },
        rightSideNav() {

            let items = [];

            navMenuItems.forEach(item => {
                if (item.side === 'right') {
                    items.push(item);
                }
            });

            return items;
        }
    },
    watch: {
        "skin": function (val) {
            store.commit('appConfig/UPDATE_SKIN', val)
        }
    },
    mounted() {

        this.locale = this.locales.find(loc => loc.code === this.$i18n.locale);

        var initCoordsState = {
            x: 0, y: 0
        }

        function animate(time) {
            requestAnimationFrame(animate)
            TWEEN.update(time)
        }

        requestAnimationFrame(animate);

        this.$root.$off('bv::modal::show');
        this.$root.$off('bv::modal::hide');

        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {

            let nav = document.getElementById("bottom-nav");

            if (nav) {
                const tween = new TWEEN.Tween(initCoordsState)
                    .to({
                        x: 0,
                        y: 88
                    }, 355)
                    .onUpdate(obj => {
                        initCoordsState = obj;
                        nav.style.setProperty('transform', `translate(${obj.x}px, ${obj.y}px)`)
                    })
                    .start();
            }

        });

        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {

            let nav = document.getElementById("bottom-nav");

            if (nav) {
                const tween = new TWEEN.Tween(initCoordsState)
                    .to({ x: 0, y: 0 }, 355)
                    .onUpdate(obj => {
                        initCoordsState = obj;
                        nav.style.setProperty('transform', `translate(${obj.x}px, ${obj.y}px)`)
                    })
                    .start();
            }

        });


    }

}

</script>

<style lang="scss" scoped>
.select-language {
    text-align: center;
    border: 1px solid rgba(26, 86, 255, 0.18);
    border-radius: 35px;
    margin: 0px 12px;
    margin-top: 9px;

    template #button-content {
        color: var(--theme-accent-light);
    }

    .select-language-flag {
        margin-right: 5px;
        height: 18px;
    }

    display: block;
    padding: 2px 10px;

    .dropdown-item {
        font-size: 14px;
    }
}

.mobile-nav-item {
    .active-link {
        color: var(--theme-accent-light) !important;
    }
}

.main-navbar {
    padding: 24px 20px;
    transition: all .3s ease;
    background: none;
}

html[md="true"] {
    .main-navbar {
        background: white;
    }
}

.dropdown-menu {
    margin-top: 5px
}

.nav-bar-toggle {
    outline: none;
    background: none;
    border: none;
    transition-delay: 0.3s;
    margin-top: 10px;

    img {
        height: 24px;
    }
}

.dropdown-toggle::after {
    border: none !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    padding: 0;
    margin: 0;
}
</style>