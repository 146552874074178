<template>
  <div
    class="d-flex navbar-container main-menu-content justify-content-between"
  >
    <div class="d-flex align-items-center">
      <showAt breakpoint="mediumAndBelow">
        <slot name="logo">
          <!-- main menu header-->
          <b-row class="nav navbar-nav navbar-nav-logo align-items-center">
            <!-- Logo & Text -->
            <b-col class="nav-item mr-auto">
              <b-link class="navbar-brand" to="/">
                <!-- <span class="brand-logo">
                  <b-img :src="appLogoImage" alt="logo" />
                </span> -->
                <span class="brand-text" style="padding-left: 10px">
                  <b-img
                    v-if="skin === 'dark'"
                    src="@/@core/assets/images/cpacer_light.svg"
                    alt="logo"
                  />
                  <b-img
                    v-else
                    src="@/@core/assets/images/cpacer.svg"
                    alt="logo"
                  />
                </span>
              </b-link>
            </b-col>
          </b-row>
        </slot>
      </showAt>

      <showAt breakpoint="large">
        <div class="d-flex">
          <slot v-if="$route.meta.verticalNavbar === 'hidden'" name="logo">
            <!-- main menu header-->
            <b-row class="nav navbar-nav navbar-nav-logo align-items-center">
              <!-- Logo & Text -->
              <b-col class="nav-item mr-auto">
                <b-link class="navbar-brand" to="/">
                  <!-- <span class="brand-logo">
                    <b-img :src="appLogoImage" alt="logo" />
                  </span> -->
                  <span class="brand-text" style="padding-left: 10px">
                    <b-img
                      v-if="skin === 'dark'"
                      src="@/@core/assets/images/cpacer_light.svg"
                      alt="logo"
                    />
                    <b-img
                      v-else
                      src="@/@core/assets/images/cpacer.svg"
                      alt="logo"
                    />
                  </span>
                </b-link>
              </b-col>
            </b-row>
          </slot>

          <horizontal-nav-menu-items :items="navMenuItems" />
        </div>
      </showAt>
    </div>

    <div
      class="nav navbar-nav flex-row align-items-center"
      style="flex-wrap: nowrap"
    >
      <b-link
        @click="toggleNotification"
        class="notification nav-item mr-auto right-horizontal-navbar-item"
      >
        <span
          v-if="notifications.count_unread > 0"
          class="notif-counter notification"
          style="top: 20px"
          >{{
            notifications.count_unread > 9 ? "9+" : notifications.count_unread
          }}</span
        >
        <img
          v-if="skin === 'dark'"
          alt="notification"
          class="notification"
          src="@/assets/images/icons/notification-light.svg"
        />
        <img
          v-else
          alt="notification"
          class="notification"
          src="@/assets/images/icons/notification.svg"
        />
      </b-link>
      <b-link
        :to="{ name: 'user-favourites' }"
        class="nav-item mr-auto right-horizontal-navbar-item"
      >
        <img
          v-if="skin === 'dark'"
          alt="like"
          class="like"
          src="@/assets/images/icons/like-light.svg"
        />
        <img
          v-else
          alt="like"
          class="like"
          src="@/assets/images/icons/like.svg"
        />
      </b-link>
      <!-- <b-link
        :to="{ name: 'user-account-info' }"
        class="nav-item mr-auto right-horizontal-navbar-item"
      >
        <span
          >{{ User.self.last_name.slice(0, 1).toUpperCase()
          }}{{ User.self.first_name.slice(0, 1).toUpperCase() }}</span
        >
      </b-link> -->
      <b-dropdown
        size="sm"
        right
        no-caret
        variant="transparent"
        class="nav-item mr-auto right-horizontal-navbar-item"
      >
        <template #button-content>
          <span
            >{{ User.self.last_name.slice(0, 1).toUpperCase()
            }}{{ User.self.first_name.slice(0, 1).toUpperCase() }}</span
          >
        </template>
        <b-dropdown-item :to="{ name: 'user-account-info' }">
          <span class="text-primary">Личный кабинет</span>
        </b-dropdown-item>
        <b-dropdown-item @click="logout()">
          <span class="text-danger">Выйти</span>
        </b-dropdown-item>
      </b-dropdown>
      <showAt breakpoint="large">
        <b-link
          v-if="User.self && User.self.group > 1"
          :to="getCurrentRoute()"
          target="_blank"
          class="nav-item mr-auto right-horizontal-navbar-item"
        >
          <feather-icon icon="SettingsIcon" size="20" class="text-muted" />
        </b-link>
      </showAt>

      <showAt breakpoint="mediumAndAbove">
        <div
          class="nav-item mr-auto right-horizontal-navbar-item right-horizontal-navbar-item-xl"
        >
          <div
            class="d-flex"
            :style="
              isVerticalMenuCollapsed && !isMouseHovered
                ? 'height: 56px;'
                : 'height: 56px; padding: 0 12px;'
            "
          >
            <b-form-checkbox
              checked="true"
              :class="
                isVerticalMenuCollapsed && !isMouseHovered
                  ? 'site-mode custom-control-primary mt-auto mb-auto ml-2'
                  : 'site-mode custom-control-primary mt-auto mb-auto'
              "
              name="dark-mode"
              v-model="skin"
              value="dark"
              unchecked-value="light"
              switch
              :size="isVerticalMenuCollapsed && !isMouseHovered ? '' : 'lg'"
            >
              <span
                v-if="isVerticalMenuCollapsed && !isMouseHovered"
                class="switch-icon-left"
              >
                <feather-icon icon="SunIcon" />
              </span>
              <span
                v-if="isVerticalMenuCollapsed && !isMouseHovered"
                class="switch-icon-right"
              >
                <feather-icon icon="MoonIcon" class="text-muted" />
              </span>
            </b-form-checkbox>

            <div
              v-if="!isVerticalMenuCollapsed || isMouseHovered"
              class="mt-auto mb-auto ml-1"
              :class="skin == 'dark' ? 'icon-dark-mode' : 'icon-light-mode'"
            ></div>
          </div>
        </div>
      </showAt>
      <showAt breakpoint="small">
        <div class="nav-item mr-auto right-horizontal-navbar-item">
          <div
            v-if="!isVerticalMenuCollapsed || isMouseHovered"
            :class="skin == 'dark' ? 'icon-dark-mode' : 'icon-light-mode'"
            @click="skin == 'dark' ? (skin = 'light') : (skin = 'dark')"
          ></div>
        </div>
      </showAt>
    </div>

    <showAt breakpoint="mediumAndAbove">
      <notification-view v-if="isShowNotif" />
    </showAt>
    <showAt breakpoint="small">
      <notification-view-mobile v-if="isShowNotif" @close="closeNotification" />
    </showAt>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import { computed } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import navMenuItems from "@/navigation/horizontal";

import notifications from "@/modules/contents/notifications";
import NotificationView from "@/components/notification/notification.desktop";
import NotificationViewMobile from "@/components/notification/notification.mobile";

import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";
import useVerticalNavMenu from "../vertical-nav-menu/useVerticalNavMenu";

import { showAt, hideAt } from "vue-breakpoints";

import User from "@/modules/user";

export default {
  data() {
    return {
      User,
      notifications,
      isShowNotif: false,
    };
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  components: {
    showAt,
    HorizontalNavMenuItems,
    NotificationView,
    NotificationViewMobile,
  },
  setup(props) {
    const { skin } = useAppConfig();
    const isDark = computed(() => skin.value === "dark");

    const { isMouseHovered, isVerticalMenuCollapsed } =
      useVerticalNavMenu(props);

    return {
      navMenuItems,
      skin,
      isDark,
      isMouseHovered,
      isVerticalMenuCollapsed,
    };
  },
  computed: {
    appLogoImage() {
      const { appName, appLogoImage } = $themeConfig.app;
      return appLogoImage;
    },
  },
  methods: {
    toggleNotification() {
      this.isShowNotif = !this.isShowNotif;

      if (!this.notifications.first_open) {
        this.notifications.first_open = true;
        this.notifications.reading();
      }
    },

    closeNotification() {
      this.isShowNotif = false;
    },

    eventToggleNotification(e) {
      if (!e.target.className.includes("notification") && this.isShowNotif) {
        this.isShowNotif = false;
      }
    },

    getCurrentRoute() {
      if (this.$route.meta.group == "administrator")
        return { name: "user-dashboard" };
      else return { name: "admin" };
    },

    logout() {
      this.$request.post("sessions.finish").then(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("current:user");
        sessionStorage.removeItem("access_token");

        // Remove dark mode
        if (document.body.className.match("dark-layout"))
          document.body.classList.remove("dark-layout");

        this.$router.replace("/authorization");
      });
    },
  },
  created() {
    window.addEventListener("click", this.eventToggleNotification);
  },
  destroyed() {
    window.removeEventListener("click", this.eventToggleNotification);
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
